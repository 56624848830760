import React from "react";
import PropTypes from "prop-types";

const LandsLogo = ({ rootCssClasses }) => (
  <svg viewBox="0 0 615 115" className={rootCssClasses}>
    <g opacity=".7">
      <path fill="#dfdd00" d="M34.39.7s-7.2,36.4,10.2,60,54,20.1,54,20.1c0,0,5.7-18.9-6.1-40.8S53.69,11.9,44.69,8.4c-4.1-1.6-7.7-4.2-10.3-7.7Z"/>
      <path fill="#dfdd00" d="M93.49,81.1c-5.2,0-10.4-.4-15.5-1.3-10.4-1.9-24.6-6.7-33.7-19s-11.2-28-11.6-39c-.2-7.1.2-14.2,1.4-21.2L34.19,0l.4.5c2.6,3.4,6.1,6.1,10.2,7.6,1.2.5,2.9,1,4.7,1.6,11.5,3.9,33,11.1,43.2,30.1,4.8,8.8,7.4,18.7,7.5,28.7.1,4.1-.4,8.3-1.4,12.3v.2h-.2s-2,.1-5.1.1ZM34.49,1.3c-1.1,6.8-1.5,13.6-1.3,20.5.4,10.9,2.6,26.5,11.6,38.7s23.1,16.9,33.3,18.8c6.7,1.2,13.5,1.6,20.2,1.2.9-3.9,1.4-8,1.3-12-.1-9.9-2.7-19.7-7.4-28.5-10-18.7-31.4-25.9-42.9-29.8-1.9-.6-3.5-1.2-4.8-1.7-3.9-1.4-7.3-3.9-10-7.2Z"/>
    </g>
    <path fill="#fff" d="M100.19,68.5c-.1-10-2.7-19.9-7.5-28.7-10.2-18.9-31.7-26.1-43.2-30-1.9-.7-3.5-1.2-4.7-1.7-4-1.5-7.6-4.1-10.2-7.6l-.3-.5-.1.6c-1.2,7-1.7,14.1-1.4,21.2.3,11,2.6,26.7,11.7,39s23.3,17.1,33.7,19c5.1.9,10.3,1.4,15.5,1.4,3.1,0,5-.2,5.1-.2h.2v-.2c.8-4,1.2-8.1,1.2-12.3ZM92.19,40.1c.8,1.5,1.5,3,2.2,4.5h-.1c-.3,16.6-5.7,27.4-6.7,29.4-5-3.4-9.7-7.3-14.2-11.4,1.3-2.7,9.8-20.6,8.1-35.7,4.4,3.7,8,8.2,10.7,13.2ZM35.69,40.5c10.2,6.4,23.2,7,23.4,7.1h0c1.6,2.2,3.3,4.2,5,6.1,2.9,3.3,5.8,6.2,8.6,8.8-2.8,0-19.8-.2-29.9-5-3.2-5.2-5.6-11-7.1-17ZM33.19,22.3c2.7,1.8,5.6,3.4,8.6,4.6,1.3.5,2.6,1,3.7,1.3.5.2,1,.3,1.5.4,3.5,6.4,7.4,12.5,11.6,18.4-2.1-.1-13.8-1.2-23-7.2-1.4-5.7-2.2-11.6-2.4-17.5ZM59.09,46.7c-4.3-5.9-8.2-12-11.6-18.4.1-.2.3-.5.5-.8,1.8-2.9,6.3-10.4,4.3-16.2,3.6,1.2,7.8,2.8,12.2,4.8,1.6,12-4.2,27.5-5.4,30.6ZM64.49,53.4c-1.7-1.9-3.4-4-5-6.2.9-2.3,7.1-18.2,5.5-30.9,5.8,2.6,11.2,6.1,16.1,10.2h-.1c1.9,14.8-6.4,32.7-7.9,35.8-2.8-2.7-5.7-5.7-8.6-8.9ZM44.59,8.6c1.3.5,2.9,1,4.8,1.7l2.3.8v.1c2.2,5.6-2.4,13.2-4.1,16.1l-.4.5c-4.7-8.6-8.9-17.4-12.6-26.5,2.6,3.3,6.1,5.8,10,7.3ZM34.39,2.3c3.7,8.8,7.8,17.4,12.4,25.8-4.8-1.4-9.4-3.6-13.5-6.4-.3-6.6.1-13,1.1-19.4ZM44.79,60.5c-.5-.7-1-1.4-1.5-2.2,10.4,4.6,27.2,4.8,29.5,4.8h.4c4.4,4.1,9,7.8,13.9,11.2-8,1.4-16.2,1.6-24.2.4-7.1-3.1-13.4-8-18.1-14.2ZM78.09,79.2c-4.6-.8-9.1-2.1-13.4-3.8,3,.4,6,.5,9,.5,4.7,0,9.4-.4,14-1.3h0c3.2,2.2,6.5,4.1,9.9,5.9-6.5.3-13.1-.1-19.5-1.3ZM98.39,80.3c-3.6-1.8-7-3.8-10.3-6.1,1-1.8,6.2-12.4,6.7-28.6,3.1,7.3,4.7,15.1,4.8,22.9.1,4-.3,8-1.2,11.8Z"/>
    <g opacity=".7">
      <path fill="#91af1c" d="M18.09,16.7s-7.2,36.4,10.2,60,54,20.1,54,20.1c0,0,5.7-18.8-6.1-40.8S37.39,27.9,28.39,24.4c-4.1-1.5-7.7-4.2-10.3-7.7Z"/>
      <path fill="#91af1c" d="M77.19,97.2c-5.2,0-10.4-.5-15.5-1.4-10.4-1.9-24.6-6.7-33.7-19s-11.3-28-11.6-39c-.2-7.1.2-14.2,1.4-21.2l.1-.6.3.5c2.6,3.4,6.1,6.1,10.2,7.6,1.2.5,2.9,1,4.7,1.7,11.5,3.9,33,11.1,43.2,30.1,4.8,8.8,7.4,18.7,7.5,28.7.1,4.1-.4,8.3-1.4,12.3v.2h-.2c.1-.1-1.9.1-5,.1ZM18.19,17.3c-1.1,6.8-1.5,13.6-1.3,20.5.3,11,2.6,26.5,11.6,38.7s23.1,16.9,33.3,18.8c6.7,1.2,13.4,1.6,20.2,1.2.9-3.9,1.4-7.9,1.3-11.9-.1-9.9-2.7-19.7-7.4-28.4-10.1-18.8-31.4-26-42.9-29.8-1.9-.6-3.5-1.2-4.8-1.7-3.9-1.6-7.3-4.1-10-7.4Z"/>
    </g>
    <path fill="#fff" d="M83.79,84.6c-.1-10-2.7-19.9-7.5-28.7-10.1-19-31.6-26.2-43.1-30.1-1.9-.6-3.5-1.2-4.7-1.7-4-1.5-7.6-4.1-10.2-7.6l-.3-.5-.1.6c-1.2,7-1.7,14.1-1.4,21.2.3,11,2.6,26.7,11.7,39,9.1,12.3,23.3,17.1,33.7,19,5.1.9,10.3,1.4,15.5,1.4,3.1,0,5-.2,5.1-.2h.2v-.2c.8-4,1.2-8.1,1.1-12.2ZM75.89,56.1c.8,1.5,1.5,3,2.2,4.5h0c-.3,16.6-5.7,27.4-6.7,29.3-5-3.4-9.8-7.2-14.2-11.4,1.3-2.7,9.8-20.6,8.1-35.7,4.3,3.8,7.9,8.3,10.6,13.3ZM19.39,56.6c10.2,6.4,23.2,7,23.4,7h0c1.6,2.2,3.3,4.2,5,6.1,2.9,3.3,5.8,6.2,8.6,8.8-2.8,0-19.8-.2-29.9-5-3.3-5.3-5.6-11-7.1-16.9ZM42.79,62.7c-4.3-5.9-8.1-12-11.6-18.4.1-.2.3-.5.5-.8,1.8-2.9,6.3-10.4,4.3-16.2,4.1,1.4,8.2,3,12.1,4.8,1.7,12.1-4.1,27.5-5.3,30.6ZM42.39,63.1c-2.2-.2-14-1.3-23.2-7.2-1.3-5.7-2.1-11.6-2.3-17.5,2.7,1.9,5.6,3.4,8.7,4.6,1.3.5,2.6,1,3.6,1.3.5.2,1,.3,1.5.4,3.6,6.3,7.4,12.5,11.7,18.4ZM48.19,69.4c-1.7-2-3.4-4-5-6.2.9-2.3,7.1-18.2,5.5-30.9,5.8,2.6,11.2,6.1,16.1,10.2h-.1c1.9,14.8-6.4,32.7-7.9,35.8-2.8-2.7-5.7-5.7-8.6-8.9ZM28.29,24.6c1.3.5,2.9,1,4.8,1.7l2.3.8v.1c2.3,5.6-2.4,13.2-4.1,16.1l-.4.5c-4.7-8.6-8.9-17.4-12.6-26.4,2.6,3.2,6.1,5.7,10,7.2ZM18.09,18.3c3.7,8.8,7.8,17.4,12.4,25.8-4.8-1.4-9.4-3.6-13.5-6.4-.3-6.5.1-13,1.1-19.4ZM28.49,76.5c-.5-.7-1-1.4-1.5-2.2,10.4,4.6,27.2,4.8,29.5,4.8h.4c4.4,4.1,9,7.8,13.9,11.2-8,1.4-16.2,1.6-24.3.4-7.1-3.1-13.3-8-18-14.2ZM61.79,95.3c-4.6-.8-9.1-2.1-13.4-3.9,3,.4,6,.5,9,.5,4.7,0,9.4-.4,14-1.3h0c3.1,2.2,6.4,4.1,9.8,5.9-6.5.4-13-.1-19.4-1.2ZM82.09,96.4c-3.6-1.8-7-3.8-10.3-6.1,1-1.8,6.2-12.4,6.8-28.6,3.1,7.3,4.7,15.1,4.8,22.9,0,3.9-.4,7.9-1.3,11.8Z"/>
    <path fill="#4ca92b" opacity=".8" d="M1.79,32.7s-7.2,36.4,10.2,60,54,20.1,54,20.1c0,0,5.6-18.8-6.1-40.8S21.09,43.9,12.09,40.4c-4.1-1.5-7.7-4.2-10.3-7.7Z"/>
    <path fill="#fff" d="M67.49,100.6c-.1-10-2.7-19.9-7.5-28.7-10.1-19-31.6-26.2-43.1-30.1-1.9-.6-3.5-1.2-4.7-1.7-4-1.5-7.6-4.1-10.2-7.6l-.4-.5-.1.6C.29,39.6-.21,46.7.09,53.7c.3,11,2.6,26.7,11.7,39s23.3,17.1,33.7,19c5.1.9,10.3,1.4,15.4,1.4,3.1,0,5-.2,5.1-.2h.2v-.1c.9-4,1.4-8.1,1.3-12.2h0ZM59.59,72.1c.8,1.5,1.6,3,2.2,4.5h-.1c-.3,16.6-5.7,27.4-6.7,29.3-5-3.4-9.7-7.2-14.2-11.4,1.3-2.7,9.8-20.6,8.1-35.7,4.4,3.8,8,8.3,10.7,13.3ZM3.09,72.6c10.2,6.4,23.2,7,23.3,7h0c1.6,2.1,3.3,4.2,5,6.1,2.9,3.3,5.8,6.2,8.6,8.8-2.8,0-19.8-.2-29.9-5-3.2-5.2-5.5-11-7-16.9ZM.59,54.3c2.7,1.8,5.6,3.4,8.6,4.6,1.3.5,2.6,1,3.6,1.3.5.2,1,.3,1.5.4,3.5,6.4,7.4,12.5,11.7,18.4-2.2-.2-14-1.3-23.2-7.2-1.2-5.7-2-11.6-2.2-17.5ZM26.49,78.7c-4.3-5.9-8.1-12-11.6-18.4.1-.2.3-.5.5-.8,1.7-2.9,6.3-10.4,4.3-16.2,3.6,1.2,7.8,2.8,12.1,4.8,1.7,12.1-4.1,27.5-5.3,30.6ZM31.89,85.4c-1.7-2-3.4-4-5-6.2.9-2.3,7.1-18.2,5.5-30.9,5.8,2.6,11.2,6.1,16.1,10.2h-.1c1.9,14.8-6.4,32.7-7.9,35.8-2.8-2.7-5.7-5.6-8.6-8.9ZM11.99,40.6c1.3.5,2.9,1,4.8,1.7l2.3.8v.1c2.3,5.6-2.4,13.2-4.1,16.1-.2.2-.4.4-.4.5-4.7-8.6-8.9-17.4-12.6-26.4,2.6,3.2,6,5.7,10,7.2ZM1.79,34.3c3.7,8.8,7.8,17.4,12.4,25.8-4.8-1.4-9.4-3.6-13.5-6.4-.3-6.5.1-13,1.1-19.4ZM12.19,92.5c-.5-.7-1-1.4-1.5-2.2,10.4,4.6,27.2,4.8,29.6,4.8h.3c4.4,4,9.1,7.8,14,11.2-8,1.4-16.2,1.6-24.2.4-7.2-3.1-13.5-8-18.2-14.2ZM45.49,111.3c-4.6-.8-9.1-2.1-13.4-3.9,3,.4,6,.5,9,.5,4.7,0,9.4-.4,14-1.3h0c3.1,2.2,6.4,4.1,9.8,5.9-6.5.4-13,0-19.4-1.2ZM65.79,112.4c-3.6-1.8-7-3.8-10.3-6.1,1-1.8,6.2-12.4,6.8-28.6,3.1,7.3,4.7,15,4.8,22.9,0,3.9-.4,7.9-1.3,11.8Z"/>
    <g isolation="isolate">
      <g isolation="isolate">
        <path fill="#383636" d="M153.32,24.77c4.28,3.51,6.42,8.44,6.42,14.78v2.86c0,4.43-1.22,8.34-3.67,11.74-2.44,3.4-5.96,5.79-10.54,7.16l19.47,34.59h-10.88l-18.21-32.53c-.61.08-1.83.11-3.67.11-3.05,0-6.07-.11-9.05-.34v32.76h-9.74V19.96c8.55-.3,15.2-.46,19.93-.46,9.01,0,15.65,1.76,19.93,5.27h0ZM123.19,27.98v27.03c4.12.38,7.56.57,10.31.57,11,0,16.5-4.47,16.5-13.4v-2.29c0-4.12-1.34-7.18-4.01-9.16-2.67-1.99-6.95-2.98-12.83-2.98-2.52,0-5.84.08-9.97.23Z"/>
        <path fill="#383636" d="M184.48,47.45c4.2-5.88,9.09-8.82,14.66-8.82,4.89,0,8.65,1.66,11.28,4.98s3.95,8.04,3.95,14.15v38.15h-9.74v-36.77c0-7.64-2.48-11.46-7.45-11.46-2.22,0-4.47.84-6.76,2.52s-4.28,3.97-5.96,6.87v38.83h-9.74V17.67h9.74v29.78h.02Z"/>
        <path fill="#383636" d="M239.41,18.82c1.11.92,1.66,2.22,1.66,3.89v3.32c0,1.68-.55,2.96-1.66,3.84-1.11.88-2.69,1.32-4.75,1.32s-3.53-.44-4.64-1.32-1.66-2.16-1.66-3.84v-3.32c0-1.68.55-2.98,1.66-3.89,1.11-.92,2.65-1.38,4.64-1.38s3.65.46,4.75,1.38ZM239.58,40.01v55.9h-9.74v-55.9h9.74Z"/>
        <path fill="#383636" d="M291.3,43.62c2.63,3.32,3.95,8.04,3.95,14.15v38.15h-9.74v-36.77c0-7.64-2.48-11.46-7.45-11.46-2.22,0-4.47.84-6.76,2.52s-4.28,3.97-5.96,6.87v38.83h-9.74v-55.9h9.16v8.36c2.06-3.13,4.37-5.54,6.93-7.22s5.33-2.52,8.3-2.52c4.89,0,8.65,1.66,11.28,4.98h.03Z"/>
        <path fill="#383636" d="M345.48,44.08c3.32,3.63,4.98,8.76,4.98,15.41v16.95c0,6.64-1.66,11.78-4.98,15.41-3.32,3.63-8.38,5.44-15.18,5.44s-11.86-1.81-15.18-5.44c-3.32-3.63-4.98-8.76-4.98-15.41v-16.95c0-6.64,1.66-11.78,4.98-15.41s8.38-5.44,15.18-5.44,11.86,1.82,15.18,5.44ZM322.63,49.87c-1.83,2.06-2.75,5-2.75,8.82v18.56c0,3.82.92,6.76,2.75,8.82s4.39,3.09,7.67,3.09,5.84-1.03,7.67-3.09c1.83-2.06,2.75-5,2.75-8.82v-18.67c0-3.82-.92-6.74-2.75-8.76-1.83-2.02-4.39-3.04-7.67-3.04s-5.84,1.03-7.67,3.09Z"/>
      </g>
      <g isolation="isolate">
        <path fill="#383636" d="M375.65,20.88v66.44h29.78v8.59h-39.52V20.88h9.74Z"/>
      </g>
      <g isolation="isolate">
        <path fill="#383636" d="M448.44,43.04c3.02,2.94,4.52,7.62,4.52,14.03v38.83h-9.28v-8.93c-4.43,6.87-9.43,10.31-15.01,10.31-4.43,0-7.96-1.53-10.6-4.58-2.63-3.05-3.95-7.22-3.95-12.49v-3.21c0-4.58,1.56-8.38,4.7-11.4,3.13-3.02,7.33-4.52,12.6-4.52,2.9,0,6.83.5,11.8,1.49v-6.3c0-3.28-.75-5.65-2.23-7.1-1.49-1.45-3.8-2.18-6.93-2.18-3.67,0-8.82.57-15.46,1.72v-8.36c7.18-1.15,12.33-1.72,15.46-1.72,6.57,0,11.36,1.47,14.38,4.41h0ZM426.5,71.16c-1.68,1.68-2.52,4.05-2.52,7.1v1.26c0,2.83.65,5.04,1.95,6.64,1.3,1.6,2.94,2.41,4.92,2.41s4.18-.9,6.36-2.69,4.18-4.33,6.01-7.62v-8.82c-2.9-.53-6.03-.8-9.39-.8s-5.65.84-7.33,2.52h0Z"/>
        <path fill="#383636" d="M504.11,43.62c2.63,3.32,3.95,8.04,3.95,14.15v38.15h-9.74v-36.77c0-7.64-2.48-11.46-7.45-11.46-2.22,0-4.47.84-6.76,2.52s-4.28,3.97-5.96,6.87v38.83h-9.74v-55.9h9.16v8.36c2.06-3.13,4.37-5.54,6.93-7.22s5.33-2.52,8.3-2.52c4.89,0,8.65,1.66,11.28,4.98h.03Z"/>
        <path fill="#383636" d="M562.59,95.91h-9.28v-9.05c-4.58,6.95-9.81,10.42-15.69,10.42-4.74,0-8.36-1.62-10.88-4.87-2.52-3.24-3.78-8-3.78-14.26v-20.39c0-6.03,1.39-10.73,4.18-14.09s6.78-5.04,11.97-5.04c2.21,0,4.54.56,6.99,1.66,2.44,1.11,4.7,2.46,6.76,4.07v-26.69h9.74v78.24h0ZM534.93,50.26c-1.49,1.95-2.23,4.91-2.23,8.88v17.76c0,7.79,2.41,11.68,7.22,11.68,2.29,0,4.56-.9,6.82-2.69,2.25-1.79,4.3-4.33,6.13-7.62v-25.2c-1.6-1.68-3.49-3.05-5.67-4.12s-4.14-1.6-5.9-1.6c-2.75,0-4.87.97-6.36,2.92h-.01Z"/>
        <path fill="#383636" d="M606.23,40.01l-.23,8.25c-3.97-.92-7.6-1.38-10.88-1.38-6.87,0-10.31,2.22-10.31,6.64v1.03c0,1.38.36,2.48,1.09,3.32.72.84,2.04,1.72,3.95,2.63l10.77,5.04c6.49,3.05,9.74,7.52,9.74,13.4v2.52c-.08,5.27-1.66,9.22-4.75,11.86s-7.96,3.95-14.6,3.95c-3.44,0-8.36-.8-14.78-2.41l.46-8.71c5.73,1.68,10.42,2.52,14.09,2.52s6.34-.52,7.79-1.55c1.45-1.03,2.18-2.73,2.18-5.1v-1.15c0-1.83-.36-3.28-1.09-4.35-.73-1.07-1.93-1.98-3.61-2.75l-11.11-4.93c-3.36-1.53-5.82-3.36-7.39-5.5s-2.35-4.62-2.35-7.45v-1.6c.15-5.19,1.78-9.11,4.87-11.74,3.09-2.63,7.96-3.95,14.61-3.95,3.21,0,7.06.46,11.57,1.38l-.02.03Z"/>
      </g>
    </g>
  </svg>
);

LandsLogo.propTypes = {
  rootCssClasses: PropTypes.string,
};

LandsLogo.defaultProps = {
  rootCssClasses: "",
};

export default LandsLogo;
